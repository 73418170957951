export const DOMAIN_REGEX =
  /^(?!-)(?:[a-zA-Z\d-]{1,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{2,63}$/g

export const SPECIAL_CHARACTERS_REGEX = /^[^!#$%^&*()\]~/}[{=?|"<>',+:;]+$/g
export const EMAIL_SPECIAL_CHARACTERS_REGEX = /^[^!#$%^&*()\]~/}[{=?|"<>',:;]+$/g
export const CYRILLIC_ALPHABET_PROHIBITED = /^[^Ѐ-ӏ]+$/g

export const LATIN_REGEX = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi
export const LATIN_NUMBER_REGEX = /^([A-Za-z0-9\s]*)$/g

export const SSH_KEY_NAME_REGEX = /^[A-Za-z0-9\s\-_-]+$/

export const INDEX_REGEX = /^(?:[A-Za-z]{2,3}-?\d+|[a-zA-Z0-9]+)$/

export const PASS_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/
export const INSTANCE_PASS_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !$+,-./[\]\\^_`{}~])[\s\S]{12,}$/

export const PASS_REGEX_ASCII = /^[\x20-\x7E]+$/
export const DISALLOW_SPACE = /^\S*$/
export const DISALLOW_PASS_SPECIFIC_CHARS = /^(?!.*["#%&'()*;<>?@|]).+$/

export const URL_REGEX =
  /^(?:https?:\/\/)(?:www\.)?[a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/

export const ADDRESS_REGEX = /^(?=.*\p{L})(?=.*[0-9]).*$/u
export const ADDRESS_SPECIAL_CHARACTERS_REGEX = /^[^!$%^&\]~}[{=?<>+]+$/g

export const CNP_REGEX = /^\d{13}$/

export const PORT_SPEED_PATTERN = /(\d+ Gbps) \((.*?)\) \((.*?) EUR\)/

// phone countries for yookassa payment method (QIWI)
export const QIWI_PHONE_COUNTRIES = [
  'am',
  'az',
  'in',
  'ru',
  'kz',
  'gb',
  'ge',
  'lt',
  'tj',
  'th',
  'uz',
  'pa',
  'lv',
  'tr',
  'md',
  'il',
  'vn',
  'ee',
  'kr',
  'kg',
]

// phone countries for yookassa payment method (SberPay)
export const SBER_PHONE_COUNTRIES = ['ru']

// VDS ids

export const VDS_IDS_LIKE_DEDICS = ['6322', '6334', '6370']
export const VDS_IDS_TO_ORDER = ['6322', '6370']

export const SALE_DEDIC_TARIFFS = [
  'Config 71',
  'Config 72',
  'Config 73',
  'Config 74',
  'Config 75',
]

export const NEW_DEDIC_TARIFFS = ['Config 79', 'Config 78', 'Config 77', 'Config 76']

export const WORDPRESS_VHOST = ['6346', '6349', '6352', '6361']

export const OFFER_FIELD = 'offer_4'

export const PROFILE_TYPES = {
  1: 'Individual',
  2: 'Company',
  3: 'Sole proprietor',
}

export const PRODUCTION_DOMAIN = 'cp.zomro.com'

export const SOC_NET = {
  google: 'google',
  vkontakte: 'vkontakte',
  facebook: 'facebook',
}

export const GOOGLE_LOGIN_LINK =
  'https://api.zomro.com/billmgr?func=oauth.redirect&newwindow=yes&network=google'

export const GOOGLE_REGISTRATION_LINK =
  'https://api.zomro.com/billmgr?func=oauth.redirect&newwindow=yes&network=google&project=4&currency=153&rparams='

export const FACEBOOK_LOGIN_LINK =
  'https://api.zomro.com/billmgr?func=oauth.redirect&newwindow=yes&network=facebook'

export const FACEBOOK_REGISTRATION_LINK =
  'https://api.zomro.com/billmgr?func=oauth.redirect&newwindow=yes&network=facebook&project=4&currency=153&rparams='

export const VK_LOGIN_LINK =
  'https://api.zomro.com/billmgr?func=oauth.redirect&newwindow=yes&network=vkontakte'

export const VK_REGISTRATION_LINK =
  'https://api.zomro.com/billmgr?func=oauth.redirect&newwindow=yes&network=vkontakte&project=4&currency=153&rparams='

export const RUS_LANG_COUNTRIES = [
  'AZ',
  'AM',
  'BY',
  'KG',
  'LV',
  'LT',
  'MD',
  'RU',
  'TJ',
  'TM',
  'UZ',
  'EE',
  'KZ',
  'GE',
]

/** ID of promotion "1 month of hosting for free" for Affordable tariff */
export const FIRST_MONTH_HOSTING_DISCOUNT_ID = '6041'

export const DEDIC_FILTER_RANGE_GROUPS = ['cpucores']
export const DEDIC_FILTER_QUICK_ACTIVATION_TAG = {
  $key: 'boolean_quick_key',
  $: 'booleanquick:true',
}

export const DC_ID_IN = { poland: 13, netherlands: 12 }

export const FOTBO_STATUSES_LIST = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'stopped',
    label: 'Stopped',
  },
  {
    value: 'verify_resize',
    label: 'Verify Resize',
  },
  {
    value: 'suspend',
    label: 'Suspended',
  },
]

export const TARIFFS_PRICES = {
  'Cloud Boost': { day: 0.2633, month: 7.9 },
  'Cloud Essential': { day: 0.216, month: 6.48 },
  'Cloud Standard': { day: 0.3827, month: 11.48 },
  'Cloud Exclusive': { day: 0.716, month: 21.48 },
  'Cloud Prime': { day: 1.0494, month: 31.48 },
  'Cloud Platinum': { day: 1.3827, month: 41.48 },
  'Cloud Enterprise': { day: 2.06, month: 61.8 },
  'Cloud Ultimate': { day: 2.7267, month: 81.8 },
  'Cloud Supreme': { day: 4.06, month: 121.8 },
  /** New version with cpu suffix */
  'Cloud Boost Intel': { day: 0.2633, month: 7.9 },
  'Cloud Essential Intel': { day: 0.216, month: 6.48 },
  'Cloud Standard Intel': { day: 0.3827, month: 11.48 },
  'Cloud Exclusive Intel': { day: 0.716, month: 21.48 },
  'Cloud Prime Intel': { day: 1.0494, month: 31.48 },
  'Cloud Platinum Intel': { day: 1.3827, month: 41.48 },
  'Cloud Enterprise Intel': { day: 2.06, month: 61.8 },
  'Cloud Ultimate Intel': { day: 2.7267, month: 81.8 },
  'Cloud Supreme Intel': { day: 4.06, month: 121.8 },

  /* AMD tariffs */
  'Cloud Boost AMD': { day: 0.2633, month: 7.9 },
  'Cloud Essential AMD': { day: 0.216, month: 6.48 },
  'Cloud Standard AMD': { day: 0.3827, month: 11.48 },
  'Cloud Exclusive AMD': { day: 0.716, month: 21.48 },
  'Cloud Prime AMD': { day: 1.0494, month: 31.48 },
  'Cloud Platinum AMD': { day: 1.3827, month: 41.48 },
  'Cloud Enterprise AMD': { day: 2.06, month: 61.8 },
  'Cloud Ultimate AMD': { day: 2.7267, month: 81.8 },
  'Cloud Supreme AMD': { day: 4.06, month: 121.8 },

  /* BASIC tariffs */
  'Cloud Starter': { day: 0.16, month: 4.8 },
  'Cloud Optimal': { day: 0.2827, month: 8.48 },
  'Cloud Nova': { day: 0.5267, month: 15.8 },
  'Cloud Pro': { day: 0.9934, month: 29.8 },
  'Cloud Max': { day: 1.4827, month: 44.48 },
  'Cloud Vertex': { day: 1.9494, month: 58.48 },
  /** New version with cpu suffix */
  'Cloud Starter Intel': { day: 0.16, month: 4.8 },
  'Cloud Optimal Intel': { day: 0.2827, month: 8.48 },
  'Cloud Nova Intel': { day: 0.5267, month: 15.8 },
  'Cloud Pro Intel': { day: 0.9934, month: 29.8 },
  'Cloud Max Intel': { day: 1.4827, month: 44.48 },
  'Cloud Vertex Intel': { day: 1.9494, month: 58.48 },
}

export const SERVICES_ID = {
  domain: 4,
  vds: 6,
  dedic: 20,
  sharedHosting: 23,
  dns: 41,
  ftp: 27090,
  siteCare: 27129,
  forex: 27136,
  vpn: 27140,
  instances: 27156,
}

/* ID of services that should be disabled: */
export const FORBIDDEN_TO_ORDER_SERVICES = [
  SERVICES_ID.ftp,
  SERVICES_ID.vds,
  SERVICES_ID.vpn,
  SERVICES_ID.siteCare,
]

/*
Below ID and names of all services

"id_itemtype": "name"
"4": "Domain names"
"6": "Virtual server"
"20": "Dedicated server"
"23": "Shared hosting"
"41": "Slave name server"
"27090": "External FTP-storage "
"27129": "Site care service"
"27136": "Forex server"
"27140": "VPN"
"27156": "Instances"
*/

export const PREMIUM_TYPE = 'premium'
export const BASIC_TYPE = 'basic'

export const METRICS_TYPE_OPTIONS = [
  { label: 'network_metrics', value: 'interface_traffic' },
  { label: 'cpu_metrics', value: 'cpu_util' },
]
export const METRICS_PERIOD_OPTIONS = [
  { label: '1h', value: '1' },
  { label: '24h', value: '24' },
  { label: '168h', value: '168' },
  { label: '720h', value: '720' },
]

export const CLOUD_DC_NAMESPACE = {
  'Netherlands-1': 14,
  'Netherlands-2': 13,
  Poland: 12,
  Staging: 15,
}

/** IDs of DC that have Basic tariffs,
 * to filter them without having to make an extra request */
export const DC_WITH_BASICS = [CLOUD_DC_NAMESPACE['Netherlands-2']]

/** Temporarily Netherlands-2 is replaced with Staging DC */
// export const DC_WITH_BASICS = [CLOUD_DC_NAMESPACE.Staging]

export const IMAGE_TYPES = {
  public: 'pub',
  own: 'own',
  marketplace: 'marketplace',
  shared: 'shr',
}

export const TIME_REGEX = /^(?:[01]\d|2[0-3]):[0-5]\d$/
export const PAYMETHODS_ORDER = ['111', '110', '108', '96', '71', '93']

export const OS_DISTRO_FILTER_LIST = [
  { value: 'debian', label: 'debian' },
  { value: 'ubuntu', label: 'ubuntu' },
  { value: 'centos', label: 'centos' },
  { value: 'almalinux', label: 'almalinux' },
  { value: 'windows', label: 'Windows' },
  { value: 'rockylinux', label: 'rockylinux' },
  { value: 'suse', label: 'suse' },
  { value: 'gnome', label: 'gnome' },
  { value: 'altlinux', label: 'altlinux' },
  { value: 'arch', label: 'arch' },
  { value: 'cirros', label: 'cirros' },
  { value: 'dos', label: 'dos' },
  { value: 'fedora', label: 'fedora' },
  { value: 'fedora-atomic', label: 'fedora-atomic' },
  { value: 'fedora-coreos', label: 'fedora-coreos' },
  { value: 'freebsd', label: 'freebsd' },
  { value: 'macos', label: 'macos' },
  { value: 'mageia', label: 'mageia' },
  { value: 'mandrake', label: 'mandrake' },
  { value: 'mandriva', label: 'mandriva' },
  { value: 'netbsd', label: 'netbsd' },
  { value: 'opensuse', label: 'opensuse' },
  { value: 'rhel', label: 'rhel' },
  { value: 'rhl', label: 'rhl' },
  { value: 'solaris', label: 'solaris' },
]

export const DETECTING_OS = 'detecting'

export const IMAGE_PAGE_LIST_TYPES = {
  snapshot: 'snapshot',
  backup: 'backup',
  images: 'images',
  schedules: 'backups-schedules',
}

export const LIMITED_CLOUD_TARIFFS = ['6871']

export const DEDIC_STATUSES = {
  ordered: '1',
  active: '2',
  stopped: '3',
  deleted: '4',
  procesing: '5',
}

export const CLOUD_DAY_PERIOD = '-50'

export const CLOUDS_ACTIONS = {
  unrescue: 'unrescue',
  unmount: 'unmount',
  resize_confirm: 'resize_confirm',
  resize_rollback: 'resize_rollback',
  start: 'fleio_start',
  stop: 'fleio_stop',
  reboot: 'reboot',
  rescue: 'rescue',
  rebuild: 'rebuild',
  boot_from_iso: 'boot_from_iso',
}

export const ERROR_ALREADY_HANDLED = 'already_handled'

export const imageRegex = /\.(jpg|jpeg|png|webp|)$/i

export const CHECK_IPv6_URL = 'https://api64.ipify.org?format=json'

export const SUPPORT_MAIL = 'support@zomro.com'

export const SUPPORT_DEPARTMENT_CATGORIES = [
  {
    value: 'setup',
    label: 'setup',
  },
  {
    value: 'optimisation',
    label: 'optimisation',
  },
  {
    value: 'smtp',
    label: 'smtp',
  },
  {
    value: 'support',
    label: 'support',
  },
  {
    value: 'other',
    label: 'other',
  },
]
export const CUSTOMER_DEPARTMENT_CATGORIES = [
  {
    value: 'ref',
    label: 'ref',
  },
  {
    value: 'funds',
    label: 'funds',
  },
  {
    value: 'services',
    label: 'services',
  },
  {
    value: 'other',
    label: 'other',
  },
]

export const BACKUP_COST = 0.0025

export const FIRST_BRACKETS_REGEXP = /^\[[^\]]*\]\s*/

export const STATUS_TYPES = {
  runing: 'success',
  active: 'success',
  stopped: 'error',
  deletion_in_progress: 'error',
  suspended: 'error',
  error: 'error',
  expired: 'error',
  'deletion in progress': 'error',
}

export const EXIST_OS_ICON = [
  'alma',
  'almalinux',
  'astralinux',
  'bitrix',
  'centos',
  'chrome',
  'debian',
  'django',
  'fedora',
  'freebsd',
  'hestiacp',
  'ispmanager',
  'lamp',
  'lemp',
  'nagios',
  'null',
  'openvpn',
  'oracle',
  'proxmox',
  'redmine',
  'rocky',
  'rockylinux',
  'teamspeak',
  'tomcat',
  'ubuntu',
  'vestacp',
  'vmmanager',
  'windows',
  'wireguard',
  'zabbix',
  'altlinux',
  'arch',
  'cirros',
  'dos',
  'gnome',
  'mageia',
  'macos',
  'mandrake',
  'netbsd',
  'opensuse',
  'rhel',
  'rhl',
  'solaris',
  'suse',
  'iso',
  'threex',
  'laravel',
  'prometheus',
  'wordpress',
  'grafana',
]
